@import "../node_modules/bootstrap/scss/bootstrap";
body {
  background: #1d2d3b;
  font-family: "Roboto", sans-serif;
}

@include media-breakpoint-up(md) {
  .main-body {
    padding: 30px;
  }
}

h1, h2 {
  color: #fefefe;
  font-size: 50px;
  font-family: 'Oswald', sans-serif;
}

h2 {
  font-size: 45px;
}

h3 {
  color: #fefefe;
  font-family: 'Roboto', sans-serif;
}

h1.main-title {
  text-transform: uppercase;
}

h2 {
  font-size: 30px;
  margin-bottom: 40px;
  text-align: center;
  text-transform: uppercase;
}

h3 {
  font-size: 28px;
}

section {
  margin: 75px 0;
}

p {
  color: white;
  font-weight: 400;
  margin-bottom: 10px;
}

.btn-primary {
  background: #DE7C2A;
  border: 1px solid #DE7C2A;
  color: #fafafa;
  font-weight: bold;
}
.btn-primary:hover {
  background: #DE982A;
  border: 1px solid #DE982A;
}

a {
  color: #ec8936;
  text-decoration: none;
}
a:hover {
  color: #DE982A;
  border-bottom: 2px solid #de982a;
}

.header {
  align-items: center;
  border-bottom: 1px solid #425260;
  display: flex;
  flex-direction: column;
  padding: 35px;
  text-align: center;
}


.section-blurb {
  border-bottom: 1px solid #425260;
  margin: 10px;
  padding: 20px 10px;
}

@include media-breakpoint-up(md) {
  .section-blurb {
    margin: 40px;
    padding: 20px 0 40px 0;
  }
}
.section-blurb p{
  font-size: 20px;
}
.section-blurb .bi {
  color: #425260;
  font-size: 55px;
  padding-bottom: 10px;
}

.callout {
  color: #8cc5b3;
  font-size: 20px;
  font-weight: 700;
  padding: 15px 0 0 0;
}

.navbar {
  background: #1d2d3b;
  box-shadow: 0 5px 7px rgba(0, 0, 0, 0.5);
  padding-bottom: 0;
}

.nav-item .nav-link {
  color: #dedede
}
.nav-item .nav-link.active {
  border-bottom: 2px solid #de7c2a;
  color: #dedede;
}
//
//.form-contact {
//  background: #425260;
//  padding: 40px;
//}
//.form-contact .form-group {
//  margin-bottom: 15px;
//}
//.form-contact .form-group .form-label {
//  color: #f0f0f0;
//  font-weight: bold;
//}

footer {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}
footer p {
  font-size: 14px;
}
